.Contact-main img{
    width: 100%;
    height: 380px;
    /* display: block; */
    top: 0;
}
.Contact-main{
  height: auto;
}

.Contact-main {
    position: relative;
    overflow: hidden;
    font-family: "Archivo", sans-serif;

  }

  .Contact-banner-container {
    background-image: url("../Img/contact-banner\ .jpg");
  
    min-height: 500px; 
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .Contact-banner-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:500px;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .Contact-banner {
    width: 100%;
    height: auto;

  }
  
  .Contact-text {
    position: absolute;
    top: 60px;
    text-align: center;
    /* padding: 10px; */
    font-family: "Archivo", sans-serif;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;
    color: white;
    width: 100%;
   

  }
  .Contact-text h5{
  font-weight: 600;
  stroke: white;
  font-size: 40px;
  margin: 0 auto;
  font-family: "Archivo", sans-serif;



  }
  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .contact2{
    margin-top: 40px;
  }

  .contact-card{
    height: 400px;
    width: 80%;
    padding: 20px;
    margin-top: 20px;
    background-color: aliceblue;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
   justify-content:space-between;
   margin-left: 40px;
  }
  .contact-card h4{
    text-align: center;
    font-weight: bold;
  }

  .contact-card p{
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
  }
