.Service-main img{
    width: 100%;
    height: 380px;
    /* display: block; */
    top: 0;
}
.Service-main{
  height: auto;
}

.Service-main {
    position: relative;
    overflow: hidden;
    font-family: "Archivo", sans-serif;

  }

  .Service-banner-container {
    background-image: url("../Img/Service-banner.webp");
  
    min-height: 500px; 
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .Service-banner-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:500px;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .Service-banner {
    width: 100%;
    height: auto;

  }
  
  .Service-text {
    position: absolute;
    top: 60px;
    text-align: center;
    /* padding: 10px; */
    font-family: "Archivo", sans-serif;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;
    color: white;
    width: 100%;
   

  }
  .Service-text h5{
  font-weight: 600;
  stroke: white;
  font-size: 40px;
  margin: 0 auto;
  font-family: "Archivo", sans-serif;



  }
  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .service2{
    height: auto;
    width: 100%;
    padding: 120px;
  }
  .service2 h4{
    color: #ffa62b;
    font-size: 30px;
    font-weight: 600;
    font-family: "Archivo", sans-serif;

  }

  .imgs-text{
    padding: 5px;
  }
  .imgs-text h4{
    margin-top: 30px;
    padding: 20px;
    color: #ffa62b;
    font-weight: 600;
    font-family: "Archivo", sans-serif;

  }


  .imgs-text h6{
    padding: 5px;
    font-weight: 600;
    font-family: "Archivo", sans-serif;

  }
  .imgs-text p{
    padding: 10px;
    font-family: "Archivo", sans-serif;
    line-height: 35px;

  }
  @media only screen and (max-width: 950px) {
    .service2{
        height: auto;
        width: 100%;
        padding: 30px;
      }
    }

    .service3{
        padding: 20px;
        margin-top: 30px;
        background-color: aliceblue;
    }
 

    .service3 h6{
        padding: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    .service3 h4{
        margin-top: 30px;
        padding: 20px;
        color: #ffa62b;
        font-weight: 600;
        font-family: "Archivo", sans-serif;
    
      }
    