.footer-main{
    height: auto;
    width: 100%;
    background-color: #1a1a1a;


}
.footer{
    padding: 80px;
    margin-top: 20px;
    font-family: "Archivo", sans-serif;

}
.company{
    display: flex;
    margin-top: 20px;

}

.company h5{
    font-size: 24px;
    margin-left: 20px;
}
.footer p{
    color: white;
}
.company img{
    width: 10%;
    height: 60%;
}
.footer p{
    color: #979797;
}

.address p{
    margin-left: 10px;
}

.pages-list h6{
    font-size: 24px;
    color: white;

}

.pages-list{
    margin-top: 20px;
}