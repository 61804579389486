.Home-main img{
    width: 100%;
    height: 380px;
    /* display: block; */
    top: 0;
}
.Home-main{
  height: auto;
}

.Home-main {
    position: relative;
    overflow: hidden;
    font-family: "Archivo", sans-serif;

  }

  .Home-banner-container {
    background-image: url("../Img/home-banner.jpg");
  
    min-height: 500px; 
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .Home-banner-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:500px;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .Home-banner {
    width: 100%;
    height: auto;

  }
  
  .Home-text {
    position: absolute;
    top: 60px;
    text-align: center;
    /* padding: 10px; */
    font-family: "Archivo", sans-serif;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;
    color: white;
    width: 100%;
   

  }
  .Home-text h5{
  font-weight: 600;
  stroke: white;
  font-size: 40px;
  margin: 0 auto;
  font-family: "Archivo", sans-serif;



  }
  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .span{
    color: #ffa62b;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;
    text-align: start;
  }
  .home2{
    background-color: white;
    padding: 20px;
  }

  .home2-image{
    width: 100%;
    padding: 20px;
    margin-top: 30px;
    margin-left: 10px;
    height:auto
  }

  .home2-image img{
    width: 100%;
    height:500px
  }

  .home2-text{
    width: 100%;
    height: auto;
    padding: 20px;
    margin-top: 30px;
    font-family: "Archivo", sans-serif;

  }

  .home2 p{
    color: #999999;
    font-family: "Archivo", sans-serif;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;

  }

  .arrow-div{
    height: 30px;
    width: 140px;
    background-color: 	#1a1a1a;
    margin-top: 20px;
    border-radius: 16px;
    color: white;
    font-size: 13px;
    display: flex;
  }

  /* .home3 {
    background-image: url("../Img/back-construction-worker-s-head-is-wearing-safety-helmet-generative-ai_114016-16.avif");
  
    min-height: 400px; 
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 40px;
  } */


  @media only screen and (max-width: 950px) {
    .color-div{
      width: 100% !important;
      height: 300px;
      }
      .home2-image img{
        width: 100%;
        height:300px
      }

      .home5-image{
        padding-right: 10px !important;
      }

      .home2-image{
        padding: 10px !important;
        margin-left: 0 !important;
      }
  
    }


  .home3{
    margin-top: 70px;
    padding: 50px;
    height: auto;
    background-color: #f6f8f9;
    font-family: "Archivo", sans-serif;

  }

  .color-div{
    background-color:#ffa62b ;
    width: 80%;
    height: 300px;

  }

  .color-div h2{
    color: white;
    font-size: 120px;
    font-weight:bolder;
    text-align: center;
  }

  .color-div h4,h5{
    color: white;
    font-size: 28px;
    text-align: center;
  }

  .center-div {
    padding: 20px;
   }
  .center-div p{
   color: #c2bebe;
  }

  .center-div h3{
    font-size: 40px;
    font-weight: 100;
  }
  .center-div h2{
    font-weight:600 ;
    font-size: 40px;
    line-height: 60px;
  }

  .text-end{
    padding: 10px;
  }
  .text-end p{
    text-align: start;
    font-family: "Archivo", sans-serif;
    line-height: 25px;
  }

  .home4{
    background-image: url("../Img/back-construction-worker-s-head-is-wearing-safety-helmet-generative-ai_114016-16.avif");
    position: relative; /* Ensure that pseudo-element is positioned relative to this div */
    height: auto;
    width: 100%;
    background-size: cover;
    padding-bottom: 50px;
    margin-top: 40px;
  }

  .home4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; /* Use percentage height to match parent div's height */
    width: 100%; /* Use percentage width to match parent div's width */
    background-color: rgba(58, 59, 59, 0.9);
  }

  .icons{
   margin-left: 40%;
   margin-top: 90px;    
  }
  .home5{
height: auto; 
padding: 40px;
 }

  .home5-text{
    height: auto;
    width: 100%;
    position: relative; /* Ensure that pseudo-element is positioned relative to this div */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home5-text p{
    color: black;
    margin-top: 40px;
  }

  .head-text{
    height: 20%;
    width: 100%;
    position: absolute;
    background-color: #f6f8f9;
    margin-top: 80px;
  }
  .head-text h4{
  text-align: center;
  margin-top: 30px;
  color: #ffa62b;
  font-weight: bold;
    }

    .head-p {
      color: black;
      margin-top: 180px;
      padding: 40px;
    }

    .home5-image{
      padding-right: 40px;
    }

    .home5-image img{
      margin-top: 80px;
      width: 100%;
    }

    .home5-image img{
  border-radius: 10px;
    }