.About-main img{
    width: 100%;
    height: 380px;
    /* display: block; */
    top: 0;
}
.About-main{
  height: auto;
}

.About-main {
    position: relative;
    overflow: hidden;
    font-family: "Archivo", sans-serif;

  }

  .About-banner-container {
    background-image: url("../Img/about-banner.jpg");
  
    min-height: 500px; 
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .About-banner-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:500px;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .About-banner {
    width: 100%;
    height: auto;

  }
  
  .About-text {
    position: absolute;
    top: 60px;
    text-align: center;
    /* padding: 10px; */
    font-family: "Archivo", sans-serif;
    opacity: 0;
    transform: translateY(50%);
    animation: slideFromBottom 3s forwards;
    color: white;
    width: 100%;
   

  }
  .About-text h5{
  font-weight: 600;
  stroke: white;
  font-size: 40px;
  margin: 0 auto;
  font-family: "Archivo", sans-serif;



  }
  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .about2{
    width: 100%;
    height: auto;
    background-color:aliceblue;
    padding: 20px;
    margin-top: 10px;

  }
  .about-text{
   margin-left: 10%;
   margin-top: 50px;
   font-family: "Archivo", sans-serif;

  }

  .about-text h2{
    font-size: 40px;
    font-weight: bold;
    color: #ffa62b;
    font-family: "Archivo", sans-serif;

  }
  .about-text p{
    line-height: 30px;
    margin-top: 30px;
    font-size: 18px;
  }

  .about-img {
    margin-top: 80px;
  }

  .about-img img{
    width:80%;
    margin-left: 40px;
  }

  .about3{
    height: auto;
    width: 100%;
    margin-top: 30px;
  }

  .vision-details {
    position: relative; /* Ensure that pseudo-element is positioned relative to this div */
    height: auto;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    background-image: url('../Img/about3.jpg');
    background-size: cover;
  }
  
  .vision-details::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; /* Use percentage height to match parent div's height */
    width: 100%; /* Use percentage width to match parent div's width */
    background-color: rgba(6, 6, 5, 0.9);
        border-radius: 10px; /* Ensure border-radius matches parent div */
  }

.sub-details h4,
.vision-details p
 {
  position: relative;
  z-index: 1;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Archivo", sans-serif;}

.sub-details{
  padding: 150px;/* Ensure text content is above the shadow */

}

@media only screen and (max-width: 950px) {
  .sub-details{
    padding: 50px !important;/* Ensure text content is above the shadow */
  
  }
  }